/* import __COLOCATED_TEMPLATE__ from './preview-module.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import type Owner from '@ember/owner';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type Conversation from 'embercom/objects/inbox/conversation';
import generateUUID from 'embercom/lib/uuid-generator';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type ContentImportService from 'embercom/services/content-import-service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  BULK_ACTION_PROGRESSION_ID,
  type BulkActionProgressionEvent,
  BulkActionProgressionValues,
} from 'embercom/services/knowledge-hub-service';
import ENV from 'embercom/config/environment';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
interface Args {
  onClose?: () => void;
  messengerUrl: string;
  previewRefreshing?: boolean;
  onPreviewLoaded?: () => void;
  showChannelSelector?: boolean;
  skipHasContentReadyForFinCheck?: boolean;
  fixedChannel?: 'email' | 'chat';
  previewLocale?: string;
  onPreviewLocaleChange?: (locale: string) => void;
  disableAutoLanguageDetection?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
    additionalHeaderActions: any;
  };
  Element: HTMLElement;
}

export const CONVERSATION_EVENTS = [
  'ThreadAssigned',
  'ThreadReopened',
  'ThreadCreated',
  'ThreadClosed',
  'ThreadUpdated',
  'ThreadSnoozed',
  'ThreadUnsnoozed',
  'ConversationAttributesUpdated',
  'ConversationPartUpdated',
  'TicketStateUpdated',
];

export type PreviewSpoofingSettings = {
  userId: string | null;
};

export const PREVIEW_CONVERSATION_STARTED_EVENT_NAME = 'PreviewConversationStarted';

export default class PreviewModule extends Component<Signature> {
  @tracked selectedTab = 'customer';
  @tracked conversationId: number | undefined;
  @tracked conversation: Conversation | undefined;
  @tracked _selectedSpoofUser: UserSummary | null = null;
  @tracked previewChannel: 'email' | 'chat' = this.args.fixedChannel ?? 'chat';

  previewSessionId: string;

  @service declare realTimeEventService: any;
  @service declare inboxApi: any;
  @service declare contentImportService: ContentImportService;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare finOptInService: FinOptInService;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  constructor(owner: Owner, args: Args) {
    super(owner, args);
    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.knowledgeHubService.subscribeToSourceSyncEvents();
    this.contentImportService.fetchContentImportSources();
    this.contentImportService.fetchContentIngestionState();
    this.aiAgentSetupService.loadKnowledgeUsageSummary();
    this.setBulkActionProgressActions();

    this.previewSessionId = generateUUID();
    this.realTimeEventService.on(
      PREVIEW_CONVERSATION_STARTED_EVENT_NAME,
      this,
      'handlePreviewConversationStarted',
    );
    CONVERSATION_EVENTS.forEach((event) => {
      this.realTimeEventService.on(event, this, 'handleAdditionalConversationEvents');
    });
  }

  @action
  onPreviewLoaded() {
    this.args.onPreviewLoaded?.();
  }

  get isFirefoxOrSafari() {
    let userAgent = navigator.userAgent.toLowerCase();
    return /firefox|safari/i.test(userAgent) && !/chrome/i.test(userAgent);
  }

  @action
  onMessage(data: Record<string, any>) {
    if (data.type === 'intercom-messenger-visible') {
      // https://github.com/intercom/intercom/issues/388796
      if (this.isFirefoxOrSafari) {
        this.restartPreview();
      }
    }
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off(
      PREVIEW_CONVERSATION_STARTED_EVENT_NAME,
      this,
      'handlePreviewConversationStarted',
    );
    this.destroyBulkActionProgressActions();
    this.knowledgeHubService.unsubscribeToSourceSyncEvents();
    CONVERSATION_EVENTS.forEach((event) => {
      this.realTimeEventService.off(event, this, 'handleAdditionalConversationEvents');
    });
  }

  get messengerUrl() {
    let iframeUrl = `${this.args.messengerUrl}&preview_session_id=${this.previewSessionId}`;

    if (this.appService.app.canUseFinPreviewAutoLanguageDetection) {
      if (this.args.previewLocale !== 'auto') {
        iframeUrl += `&language_override=${this.args.previewLocale ?? 'en'}`;
      }
    } else if (this.args.previewLocale !== 'auto') {
      iframeUrl += `&language_override=${this.args.previewLocale ?? 'en'}`;
    } else {
      iframeUrl += `&language_override=en`;
    }

    return iframeUrl;
  }

  get hasContentReadyForFin() {
    return (
      this.aiAgentSetupService.hasContentReadyForFin &&
      this.contentImportService?.finIngestionState?.hasIngestedContent
    );
  }

  get hasNoContentAndIsIngesting() {
    return !this.hasContentReadyForFin && this.contentImportService?.inProgressRunExists;
  }

  get canUseImpersonation(): boolean {
    return (
      this.appService.app.canUseFinPreviewImpersonation &&
      this.permissionsService.currentAdminCan('can_access_contacts') &&
      this.permissionsService.currentAdminCan('can_access_user_profiles')
    );
  }

  @action
  handlePreviewConversationStarted(event: any) {
    let eventConversationId = Number(event.conversationId);
    let eventPreviewSessionId = event.previewSessionId;

    // if the preview conversation is from a different session, we don't want to fetch the conversation
    if (eventPreviewSessionId !== this.previewSessionId) {
      return;
    }

    if (!eventConversationId) {
      return;
    }

    this.conversationId = eventConversationId;
    taskFor(this.fetchConversation).perform();
  }

  @action
  handleAdditionalConversationEvents(event: any) {
    let eventConversationId = Number(event.conversationId);

    if (eventConversationId !== this.conversationId) {
      return;
    }

    // For now let's just re-fetch the conversation when we get any of these events
    taskFor(this.fetchConversation).perform();
  }

  @task({ drop: true })
  *fetchConversation(): TaskGenerator<void> {
    if (!this.conversationId) {
      return;
    }

    let conversation = yield this.inboxApi.fetchConversation(this.conversationId);
    this.conversation = conversation;
  }

  get isFetchingConversation() {
    return taskFor(this.fetchConversation).isRunning;
  }

  @action
  restartPreview() {
    // find the iframe in the dom by id
    let iframe = window.document.querySelector(
      '#hosted-messenger-unified-preview',
    ) as HTMLIFrameElement;

    let payload = {
      type: 'show-new-conversation',
    };

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify(payload));
      this.conversation = undefined;
      this.conversationId = undefined;
      taskFor(this.fetchConversation).cancelAll();
    }
  }

  @action
  setSelectedSpoofUser(user: UserSummary | null) {
    this._selectedSpoofUser = user;
    this.sendSpoofingSettingsToMessenger();
    if (user != null) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'selected',
        object: 'preview_impersonation_user',
      });
    }
  }

  sendSpoofingSettingsToMessenger() {
    let settings: PreviewSpoofingSettings = {
      userId: this._selectedSpoofUser?.id ?? null,
    };

    let iframe = window.document.querySelector(
      '#hosted-messenger-unified-preview',
    ) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        JSON.stringify({
          type: 'preview-settings-updated',
          previewSpoofingSettings: settings,
        }),
      );
    }
  }

  // the component requires undefined rather than null
  get selectedSpoofUser(): UserSummary | undefined {
    return this._selectedSpoofUser ?? undefined;
  }

  private setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyBulkActionProgressActions() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    if (event.status === BulkActionProgressionValues.Complete) {
      if (!event.all_entities_unaffected) {
        setTimeout(() => {
          this.knowledgeHubService.fetchKnowledgeUsageSummary();
        }, ENV.APP._500MS);
      }
    }
  }

  @action
  togglePreviewChannel() {
    this.previewChannel = this.previewChannel === 'email' ? 'chat' : 'email';
  }

  @task({ drop: true })
  *consentToExternalAi() {
    yield taskFor(this.finOptInService.consentToExternalAi).perform();
  }

  get app() {
    return this.appService.app;
  }

  get isFinPreview() {
    return this.messengerUrl.includes('fin_demo_preview');
  }

  get showPreviewControls() {
    if (this.app.canUseGlobalAiOptOut && !this.app.hasConsentedToExternalAi && this.isFinPreview) {
      return false;
    }

    if (this.app.isTestApp && this.isFinPreview) {
      return false;
    }

    return this.hasContentReadyForFin || this.args.skipHasContentReadyForFinCheck;
  }

  @action
  onTabChange(tab: string) {
    this.selectedTab = tab;

    if (tab === 'teammate') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'preview_teammate_tab',
        place: 'workflow_preview',
        section: 'preview',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview::PreviewModule': typeof PreviewModule;
    'workflows/preview/preview-module': typeof PreviewModule;
  }
}
